import { BackTop } from 'antd';
import clsx from 'clsx';
import React from 'react';
import TripleAngle from '../../svgs/triple-angle';
import * as styles from './index.module.scss';

type PropTypes = {
  className?: string;
};

export default function LuScrolltopButton({className}: PropTypes) {
  return (
    <BackTop className={styles.backtop}>
      <div className={clsx(styles.top, className)} onClick={scrollToTop}>
        <div className={styles.icon}>
          <TripleAngle />
        </div>
        <div className={styles.text}>TOP</div>
      </div>
    </BackTop>
  );
}

function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}
