import React, {useState} from 'react';
import {Row, Col, Tooltip, Popover, Collapse} from 'antd';
import * as styles from './index.module.scss';
import LuScrolltopButton from '../lu-scrolltop-button';
import Logo from '../../svgs/logo';
import {StaticImage} from 'gatsby-plugin-image';
import {Link} from 'gatsby';
import clsx from 'clsx';
import {GAP_SIZE_2, GAP_SIZE_BASE} from '../../constants';
import {useTranslation} from 'react-i18next';
import {CaretRightOutlined} from '@ant-design/icons';
import './index.scss';
import officialAccountImage from '../../images/icon-official-account.png';
import officialAccountActiveImage from '../../images/icon-official-account-active.png';

export default function LuFooter() {
  const [wechatHighlight, setWechatHighlight] = useState(false);
  const {t} = useTranslation('footer');

  // 与 header 中的 menu 区分开来，以防未来修改
  const FOOTERS = [
    {
      title: t('走进新联和'),
      link: '/about',
      subMenus: [
        {
          title: t('集团概况'),
          link: '/about/#about-1',
        },
        {
          title: t('发展历程'),
          link: '/about/#about-2',
        },
        {
          title: t('管理团队'),
          link: '/about/#about-3',
        },
        {
          title: t('ERP智慧工厂'),
          link: '/about/#about-4',
        },
        {
          title: t('基地介绍'),
          link: '/about/#about-5',
        },
        {
          title: t('品牌认可'),
          link: '/about/#about-6',
        },
      ],
    },
    {
      title: t('旗下品牌'),
      link: '/brands',
      subMenus: [
        {
          title: t('联丰针织'),
          link: '/brands/#brands-1',
        },
        {
          title: t('恒益蕾丝'),
          link: '/brands/#brands-2',
        },
        {
          title: t('联丰染整'),
          link: '/brands/#brands-3',
        },
        {
          title: t('联丰检测'),
          link: '/brands/#brands-4',
        },
      ],
    },
    {
      title: t('企业动态'),
      link: '/news',
    },
    {
      title: t('社会责任'),
      link: '/responsibility',
      subMenus: [
        {
          title: t('可持续发展'),
          link: '/responsibility/#respon-1',
        },
        {
          title: t('校企合作'),
          link: '/responsibility/#respon-2',
        },
      ],
    },
    {
      title: t('人才储备'),
      link: '/career',
      subMenus: [
        {
          title: t('员工生活'),
          link: '/career/#employee-1',
        },
        {
          title: t('加入我们'),
          link: '/career/#employee-2',
        },
      ],
    },
    {
      title: t('联系我们'),
      link: '/contact',
    },
  ];
  return (
    <>
      {/* PC 端 footer */}
      <footer className={styles.footer}>
        <LuScrolltopButton className={styles.scrolltopButton} />
        <div className={styles.container}>
          <Row
            className={clsx(styles.menus)}
            gutter={[GAP_SIZE_2, GAP_SIZE_2]}
            justify="center"
            align="top"
            wrap={false}
          >
            {/* 第一行：logo + 菜单 */}
            <Col className={styles.logo} md={6} span={24}>
              <Logo />
            </Col>
            {FOOTERS.map(menu => (
              <Col key={menu.title} className={styles.menu} flex="1" xs={24}>
                <div className={styles.menuTitle}>
                  <Link to={menu.link}>{menu.title}</Link>
                </div>
                {menu.subMenus &&
                  menu.subMenus.map(subMenu => (
                    <div key={subMenu.title} className={styles.subTitle}>
                      <Link to={subMenu.link}>{subMenu.title}</Link>
                    </div>
                  ))}
              </Col>
            ))}
          </Row>
          {/* 第二行：logo + copyright + 地址 */}

          <Row
            gutter={[GAP_SIZE_2, GAP_SIZE_2]}
            justify="center"
            align="middle"
          >
            <Col lg={6} span={24}>
              <Row justify="center" align="middle" gutter={GAP_SIZE_BASE}>
                <Col>
                  <Popover
                    title={null}
                    trigger="click"
                    onVisibleChange={visible => {
                      setWechatHighlight(visible);
                    }}
                    content={
                      <StaticImage
                        src="../../images/official-account-qrcode.png"
                        layout="fixed"
                        placeholder="blurred"
                        alt="qrcode"
                        width={150}
                        height={150}
                      />
                    }
                  >
                    <div>
                      {wechatHighlight ? (
                        <img
                          className={styles.iconOfficialAccount}
                          src={officialAccountActiveImage}
                          alt="公众号"
                          style={{height: 26}}
                        />
                      ) : (
                        <img
                          className={styles.iconOfficialAccount}
                          src={officialAccountImage}
                          alt="公众号"
                          style={{height: 26}}
                        />
                      )}
                    </div>
                  </Popover>
                </Col>
                <Col>
                  <a href="https://weibo.com/u/7669083282" target="_blank">
                    <StaticImage
                      className={styles.iconWeibo}
                      src="../../images/icon-weibo.png"
                      alt="微博"
                      height={26}
                      layout="fixed"
                    />
                  </a>
                </Col>
              </Row>
            </Col>
            <Col className={styles.copyright} lg={9} span={24}>
              {t('版权信息声明：Copyright©新联和公司版权所有')}
            </Col>
            <Col className={styles.address} lg={9} span={24}>
              {t('公司备案地址：香港九龙青山道538号半岛大厦11/F楼09室')}
            </Col>
          </Row>
        </div>
      </footer>
      {/* 移动端 footer */}
      <footer className={styles.smFooter}>
        <div className={styles.content}>
          <Row
            className={styles.firstRow}
            justify="space-between"
            align="middle"
          >
            <Col className={styles.logo}>
              <Logo />
            </Col>
            <Col>
              {' '}
              <Row justify="center" align="middle" gutter={GAP_SIZE_BASE}>
                <Col>
                  <Popover
                    title={null}
                    trigger="click"
                    onVisibleChange={visible => {
                      setWechatHighlight(visible);
                    }}
                    content={
                      <StaticImage
                        src="../../images/official-account-qrcode.png"
                        layout="fixed"
                        placeholder="blurred"
                        alt="qrcode"
                        width={150}
                        height={150}
                      />
                    }
                  >
                    <div>
                      {wechatHighlight ? (
                        <img
                          className={styles.iconOfficialAccount}
                          src={officialAccountActiveImage}
                          alt="公众号"
                          style={{height: 26}}
                        />
                      ) : (
                        <img
                          className={styles.iconOfficialAccount}
                          src={officialAccountImage}
                          alt="公众号"
                          style={{height: 26}}
                        />
                      )}
                    </div>
                  </Popover>
                </Col>
                <Col>
                  <a href="https://weibo.com/u/7669083282" target="_blank">
                    <StaticImage
                      className={styles.iconWeibo}
                      src="../../images/icon-weibo.png"
                      alt="微博"
                      height={26}
                      layout="fixed"
                    />
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
          <Collapse
            className={clsx(styles.collapse, 'footer-collapse')}
            accordion
            bordered={false}
            expandIcon={({isActive}) => (
              <div className={styles.expandIcon}>
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              </div>
            )}
          >
            {FOOTERS.map(menu => (
              <Collapse.Panel
                header={<div className={styles.header}>{menu.title}</div>}
                key={menu.title}
                className={styles.panel}
              >
                {menu.subMenus?.map(subMenu => (
                  <div key={subMenu.title} className={styles.subMenu}>
                    <Link className={styles.link} to={subMenu.link}>
                      {subMenu.title}
                    </Link>
                  </div>
                ))}
              </Collapse.Panel>
            ))}
          </Collapse>
          <div className={styles.thirdRow}>
            <Row
              justify="space-between"
              align="middle"
              wrap={false}
              gutter={GAP_SIZE_BASE}
            >
              <Col className={styles.address}>
                <div>{t('版权信息声明：Copyright©新联和公司版权所有')}</div>
                <div>
                  {t('公司备案地址：香港九龙青山道538号半岛大厦11/F楼09室')}
                </div>
              </Col>
              <Col>
                <LuScrolltopButton />
              </Col>
            </Row>
          </div>
        </div>
      </footer>
    </>
  );
}
