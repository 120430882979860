import React, {useCallback, useState} from 'react';
import {Col, Divider, Drawer, Row} from 'antd';
import {StaticImage} from 'gatsby-plugin-image';
import * as styles from './index.module.scss';
import Logo from '../../svgs/logo';
import clsx from 'clsx';
import {MenuOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import {Link, useI18next} from 'gatsby-plugin-react-i18next';
type PropTypes = {
  theme: 'white' | 'default';
  float: boolean;
};
export default function LuMenu({theme = 'default', float = false}: PropTypes) {
  const {originalPath, language} = useI18next();
  const [showDrawer, setShowDrawer] = useState(false);
  const {t} = useTranslation('menu');
  const isEnglish = language === 'en';
  const MENUS = [
    {
      title: t('走进新联和'),
      link: '/about',
      subMenus: [
        {
          title: t('集团概况'),
          link: '/about/#about-1',
        },
        {
          title: t('发展历程'),
          link: '/about/#about-2',
        },
        {
          title: t('管理团队'),
          link: '/about/#about-3',
        },
        {
          title: t('ERP智慧工厂'),
          link: '/about/#about-4',
        },
        {
          title: t('基地介绍'),
          link: '/about/#about-5',
        },
        {
          title: t('品牌认可'),
          link: '/about/#about-6',
        },
      ],
    },
    {
      title: t('旗下品牌'),
      link: '/brands',
      subMenus: [
        {
          title: t('联丰针织'),
          link: '/brands/#brands-1',
        },
        {
          title: t('恒益蕾丝'),
          link: '/brands/#brands-2',
        },
        {
          title: t('联丰染整'),
          link: '/brands/#brands-3',
        },
        {
          title: t('联丰检测'),
          link: '/brands/#brands-4',
        },
      ],
    },
    {
      title: t('企业动态'),
      link: '/news',
    },
    {
      title: t('社会责任'),
      link: '/responsibility',
      subMenus: [
        {
          title: t('可持续发展'),
          link: '/responsibility/#respon-1',
        },
        {
          title: t('校企合作'),
          link: '/responsibility/#respon-2',
        },
      ],
    },
    {
      title: t('人才储备'),
      link: '/career',
      subMenus: [
        {
          title: t('员工生活'),
          link: '/career/#employee-1',
        },
        {
          title: t('加入我们'),
          link: '/career/#employee-2',
        },
      ],
    },
    {
      title: t('联系我们'),
      link: '/contact',
    },
  ];
  const renderMenu = useCallback(
    (insideDrawer: boolean = false) => (
      <>
        <ul className={styles.menuList}>
          {MENUS.map(menu => (
            <li key={menu.title} className={styles.menuItem}>
              <Link to={menu.link}>{menu.title}</Link>
              {menu.subMenus && (
                <ul className={styles.subList}>
                  {menu.subMenus.map(subMenu => (
                    <li key={subMenu.title} className={styles.subItem}>
                      <Link to={subMenu.link}>{subMenu.title}</Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
        {insideDrawer && <Divider />}
        <ul className={styles.menuList}>
          <li className={styles.menuItem}>
            <a>{t('公众号')}</a>
            <StaticImage
              className={styles.qrcode}
              src="../../images/official-account-qrcode.png"
              layout="fixed"
              alt="qrcode"
              width={100}
              height={100}
            />
          </li>
          <li className={styles.menuItem}>
            <Link to={originalPath} language="zh-Hans">
              简
            </Link>
            <span>·</span>
            <Link to={originalPath} language="zh-Hant">
              繁
            </Link>
            <span>·</span>
            <Link to={originalPath} language="en">
              EN
            </Link>
          </li>
        </ul>
      </>
    ),
    [MENUS]
  );
  return (
    <>
      <header
        className={clsx(
          styles.header,
          float && styles.float,
          styles[`${theme}Theme`]
        )}
      >
        <Row
          className={clsx(styles.container, isEnglish && styles.english)}
          justify="space-between"
          align="middle"
        >
          {/* logo */}
          <Col className={styles.logo}>
            <Link to="/">
              <Logo />
            </Link>
          </Col>
          {/* 菜单 */}
          <Col className={styles.nav}>{renderMenu()}</Col>
          <Col className={styles.hamburger} onClick={() => setShowDrawer(true)}>
            <MenuOutlined />
          </Col>
        </Row>
        <div className={styles.bg} />
      </header>
      {/* 小屏幕下的顶部导航栏 */}
      <Drawer
        destroyOnClose
        title={null}
        placement="top"
        visible={showDrawer}
        height={600}
        onClose={() => setShowDrawer(false)}
      >
        <div className={styles.drawer}>{renderMenu(true)}</div>
      </Drawer>
    </>
  );
}
