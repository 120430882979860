import React from 'react';

const svgStyle = {
  height: '1em',
};

export default function Logo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 196.95 47.68"
      style={svgStyle}
    >
      <g id="图层_2" data-name="图层 2">
        <g id="design">
          <path
            fill="currentColor"
            d="M102.54,5.75v24h11.81a2.77,2.77,0,0,0,2.54-3V4H102.54ZM113,25.68a.9.9,0,0,1-.82,1h-5.63V7.11H113Z"
          />
          <path
            fill="currentColor"
            d="M95.07,5.14c.84-.08,1.68-.19,2.47-.31a3,3,0,0,0,2.35-3V.56S96.61,1.78,86.25,2.33l.08,3s2.06.08,4.74,0v2.3H84.12V8a2.77,2.77,0,0,0,2.77,2.77h4.18V32.1h4V10.78h3.65A2.77,2.77,0,0,0,101.49,8V7.67H95.07Z"
          />
          <path
            fill="currentColor"
            d="M90.17,11.67l-2.76,0A120.15,120.15,0,0,1,85,28.89l3.63.87A115.15,115.15,0,0,0,90.17,11.67Z"
          />
          <path
            fill="currentColor"
            d="M96,11.67a115.15,115.15,0,0,0,1.55,18.09l3.63-.87a120.15,120.15,0,0,1-2.42-17.24Z"
          />
          <path
            fill="currentColor"
            d="M15.77,18.43a2.77,2.77,0,0,0,2.77-2.77v-.34H11.75V13.55h4.49A2.76,2.76,0,0,0,19,10.78v-.34H14.15a21.35,21.35,0,0,0,3-4.25l-.74-.55a2.77,2.77,0,0,0,2.18-2.7V2.6H11.75V0H8.24V2.6H1.44v.34A2.78,2.78,0,0,0,3.77,5.67L3,6.62a16.12,16.12,0,0,1,2.69,3.82H1v.34a2.77,2.77,0,0,0,2.77,2.77H8.24v1.77H1.44v.34a2.77,2.77,0,0,0,2.77,2.77h4v9.26a.92.92,0,0,1-.88,1H4.93v3.11H9.05a2.84,2.84,0,0,0,2.7-3V18.43ZM6.32,5.7h7a16.76,16.76,0,0,1-2.81,4.07l.57.67h-2l.33-.24A21.66,21.66,0,0,0,6.32,5.7Z"
          />
          <path
            fill="currentColor"
            d="M35.7,10.78v-.34H23.86V5.67a55.08,55.08,0,0,0,7.83-.58,3,3,0,0,0,2.35-3V.83S30.75,2.05,20.4,2.6V23.33c0,5.54-2.84,7.33-2.84,7.33l1.55,1.61a10.62,10.62,0,0,0,4.75-8.79V13.55h5V31.9h3.51V13.55h.6A2.77,2.77,0,0,0,35.7,10.78Z"
          />
          <path
            fill="currentColor"
            d="M14.49,19.47l-2,1.53a37.52,37.52,0,0,1,3.4,6.89l2.93-2.16A29.11,29.11,0,0,0,14.49,19.47Z"
          />
          <path
            fill="currentColor"
            d="M7.56,20.33l-2.62-1C2.22,25.59,0,28.09,0,28.09l3,2.05A52.36,52.36,0,0,0,7.56,20.33Z"
          />
          <path
            fill="currentColor"
            d="M72.71,21.57a.9.9,0,0,1-.83,1H70.77V17.45H67.45V31.9h3.32V25.64h2.71a2.78,2.78,0,0,0,2.54-3V17.45H72.71Z"
          />
          <path
            fill="currentColor"
            d="M62.73,22.53h-1a.9.9,0,0,1-.83-1V17.45H57.55v5.23a2.78,2.78,0,0,0,2.54,3h2.46c-.59,3.74-2.5,5-2.5,5l1.46,1.61A10.7,10.7,0,0,0,66,23.48v-6H62.73Z"
          />
          <path
            fill="currentColor"
            d="M56.2,19.69l-1.09.38V5.12h.48a2.77,2.77,0,0,0,2.77-2.77V2h-16v.34A2.77,2.77,0,0,0,45,5.12V22.88l-2.81.6,1.17,4.46a81.39,81.39,0,0,0,7.84-2.52l.44-.18V31.9h3.51V23.29a13.42,13.42,0,0,0,1.68-1.37Zm-7.73-8.45H51.6v3.25H48.47ZM51.6,8.13H48.47v-3H51.6ZM48.47,22V17.6H51.6v3.57C50.67,21.44,49.62,21.73,48.47,22Z"
          />
          <path
            fill="currentColor"
            d="M62.86,11.37l.26,1.24L60.57,13S63.25,11,65.35,9.18a2,2,0,0,0,.2-2.71l-.82-1s-.46.6-1.23,1.48l-3,.25a21.11,21.11,0,0,0,3.91-3.32,1.84,1.84,0,0,0-.05-2.37L63.16.19S60.36,4.76,56.35,7l.94,3.19,3.42-.3a21.88,21.88,0,0,1-4.3,3.28l.7,3.07,6.58-.83.25,1.2,2.38-.9a21.81,21.81,0,0,0-1.61-4.9Z"
          />
          <path
            fill="currentColor"
            d="M73,11.37l.26,1.24L70.72,13S73.4,11,75.5,9.18a2,2,0,0,0,.2-2.71l-.82-1s-.46.6-1.23,1.48l-3,.25a21.11,21.11,0,0,0,3.91-3.32,1.84,1.84,0,0,0,0-2.37L73.31.19S70.51,4.76,66.5,7l.94,3.19,3.42-.3a21.88,21.88,0,0,1-4.3,3.28l.7,3.07,6.58-.83.25,1.2,2.38-.9a21.81,21.81,0,0,0-1.61-4.9Z"
          />
          <path
            fill="currentColor"
            d="M138.86,18.81h15.5A2.78,2.78,0,0,0,157.14,16v-.32H149v-1h5.4a2.78,2.78,0,0,0,2.78-2.78V11.6H149v-1h5.4a2.79,2.79,0,0,0,2.78-2.78V7.48H149v-1h5.4a2.79,2.79,0,0,0,2.78-2.79V3.37H149V1.52h-4V3.37h-8.87v.32a2.78,2.78,0,0,0,2.78,2.79H145v1h-8.87v.33a2.78,2.78,0,0,0,2.78,2.78H145v1h-8.87v.32a2.77,2.77,0,0,0,2.78,2.78H145v1h-8.87V16A2.77,2.77,0,0,0,138.86,18.81Z"
          />
          <path
            fill="currentColor"
            d="M144.24,19.35h-4v1.82H126.11v.34a2.77,2.77,0,0,0,2.77,2.77h8.71a59.56,59.56,0,0,1-10.83,3.47l2,3.74A53.12,53.12,0,0,0,140.23,26V32.1h4V26a53.12,53.12,0,0,0,11.48,5.51l2-3.74a59.54,59.54,0,0,1-10.84-3.47h8.72a2.77,2.77,0,0,0,2.76-2.77v-.34H144.24Z"
          />
          <path
            fill="currentColor"
            d="M129.9,8.05v11.3h4v-14l.37-.28a3.2,3.2,0,0,0,.87-3.86L134.64.1a59.36,59.36,0,0,1-9,6.85L127,9.64A30.23,30.23,0,0,0,129.9,8.05Z"
          />
          <path
            fill="currentColor"
            d="M171.91,22.48v.3A2.13,2.13,0,0,0,174,24.91h2.68v2.75h3.79V24.91H186v.23a.71.71,0,0,1-.64.75h-1v2.46h3.57a2.17,2.17,0,0,0,2-2.34v-1.1h1A2.13,2.13,0,0,0,193,22.78v-.3h-3.13V21.41h1A2.13,2.13,0,0,0,193,19.28v-.8h-2.51V19h-6.13V18H191a2,2,0,0,0,1.89-2.14V9.33h-8.57V8.55h6.5A2.14,2.14,0,0,0,193,6.41V6.12h-8.64V5.28h-3.79v.84h-8.63v.29A2.13,2.13,0,0,0,174,8.55h6.5v.78H172V18h8.57v1h-8.63v.29A2.13,2.13,0,0,0,174,21.41H186v1.07ZM190.43,15a.65.65,0,0,1-.61.69h-5.49v-1h6.1Zm0-3.46v.87h-6.1v-.87Zm-16,0h6.1v.87h-6.1Zm0,4.15v-1h6.1v1Z"
          />
          <path
            fill="currentColor"
            d="M193.62,1.67H167.8V32.1h26.6A2.73,2.73,0,0,0,197,29.22V1.67Zm0,26.48a.88.88,0,0,1-.82.93H171.13V4.69h22.49Z"
          />
          <path
            fill="currentColor"
            d="M85.92,38.2v7.11a2.38,2.38,0,0,0,2.38,2.37h2.32A2.37,2.37,0,0,0,93,45.31V38.2H90.9v6.87a.76.76,0,0,1-.77.76H88.78a.76.76,0,0,1-.76-.76V38.2Z"
          />
          <path
            fill="currentColor"
            d="M118.63,47.68h2.8a2.37,2.37,0,0,0,2.37-2.37V40.57a2.37,2.37,0,0,0-2.37-2.37h-2.8a2.38,2.38,0,0,0-2.37,2.37v4.74A2.38,2.38,0,0,0,118.63,47.68Zm-.27-6.86a.76.76,0,0,1,.76-.77h1.82a.76.76,0,0,1,.77.77v4.25a.76.76,0,0,1-.77.76h-1.82a.76.76,0,0,1-.76-.76Z"
          />
          <polygon
            fill="currentColor"
            points="99.92 42.37 102.94 47.68 105.04 47.68 105.04 38.2 102.94 38.2 102.94 43.46 99.92 38.2 97.82 38.2 97.82 47.68 99.92 47.68 99.92 42.37"
          />
          <polygon
            fill="currentColor"
            points="130.49 42.37 133.52 47.68 135.61 47.68 135.61 38.2 133.52 38.2 133.52 43.46 130.49 38.2 128.4 38.2 128.4 47.68 130.49 47.68 130.49 42.37"
          />
          <rect
            fill="currentColor"
            x="109.69"
            y="38.2"
            width="2.09"
            height="9.49"
          />
          <polygon
            fill="currentColor"
            points="58.87 42.37 61.89 47.68 63.99 47.68 63.99 38.2 61.89 38.2 61.89 43.46 58.87 38.2 56.77 38.2 56.77 47.68 58.87 47.68 58.87 42.37"
          />
          <rect
            fill="currentColor"
            x="50.03"
            y="38.2"
            width="2.09"
            height="9.49"
          />
          <path
            fill="currentColor"
            d="M40.38,38.2v9.48h4.85A1.65,1.65,0,0,0,46.88,46v-.2H42.47V38.2Z"
          />
          <path
            fill="currentColor"
            d="M70.15,43.64l3.24,4h2.85L72.55,43.3,75,40.83a1.66,1.66,0,0,0,0-2.34l-.14-.14-3.58,3.47-1.17-1.39V38.2h-2.1v9.48h2.1Z"
          />
          <path
            fill="currentColor"
            d="M149.23,40.05h2.39a1.65,1.65,0,0,0,1.66-1.65v-.2h-4.54a2.37,2.37,0,0,0-2.37,2.37v4.74a2.37,2.37,0,0,0,2.37,2.37h2.37a2.37,2.37,0,0,0,2.37-2.37V41.44h-4v.2a1.66,1.66,0,0,0,1.66,1.66h.21v1.76a.76.76,0,0,1-.76.76h-1.4a.76.76,0,0,1-.76-.76V40.82A.77.77,0,0,1,149.23,40.05Z"
          />
          <path
            fill="currentColor"
            d="M179,38.2v7.11a2.37,2.37,0,0,0,2.37,2.37h2.33a2.37,2.37,0,0,0,2.37-2.37V38.2H184v6.87a.77.77,0,0,1-.77.76h-1.35a.76.76,0,0,1-.76-.76V38.2Z"
          />
          <path
            fill="currentColor"
            d="M170.14,47.68h2.79a2.38,2.38,0,0,0,2.38-2.37V40.57a2.38,2.38,0,0,0-2.38-2.37h-2.79a2.37,2.37,0,0,0-2.37,2.37v4.74A2.37,2.37,0,0,0,170.14,47.68Zm-.28-6.86a.76.76,0,0,1,.77-.77h1.82a.76.76,0,0,1,.76.77v4.25a.76.76,0,0,1-.76.76h-1.82a.76.76,0,0,1-.77-.76Z"
          />
          <path
            fill="currentColor"
            d="M189.65,40.05v7.63h2.1V43.3h2.42a2.38,2.38,0,0,0,2.37-2.38v-.35a2.37,2.37,0,0,0-2.37-2.37h-4.52Zm4.1,0a.66.66,0,0,1,.49.21.67.67,0,0,1,.21.49.69.69,0,0,1-.7.69h-2V40.05Z"
          />
          <path
            fill="currentColor"
            d="M164.18,40.92v-.35a2.37,2.37,0,0,0-2.37-2.37h-4.52v9.48h2.1V43.3h.94l2,4.38h2.3l-2-4.51A2.37,2.37,0,0,0,164.18,40.92Zm-4.79-.87h2a.67.67,0,0,1,.49.21.66.66,0,0,1,.21.49.69.69,0,0,1-.7.69h-2Z"
          />
          <path
            fill="currentColor"
            d="M6.71,47.68V40.05h.83A1.65,1.65,0,0,0,9.19,38.4v-.2H2.13v.2a1.65,1.65,0,0,0,1.65,1.65h.83v7.63Z"
          />
          <path
            fill="currentColor"
            d="M14.89,43.3h1.35a1.65,1.65,0,0,0,1.65-1.66v-.2h-3V38.2h-2.1v9.48h2.1Z"
          />
          <rect
            fill="currentColor"
            x="17.91"
            y="38.2"
            width="2.09"
            height="9.49"
          />
          <path
            fill="currentColor"
            d="M29.36,40.05A1.65,1.65,0,0,0,31,38.4v-.2H24.5v9.48h4.86A1.65,1.65,0,0,0,31,46v-.2H26.6V43.3h2.76A1.65,1.65,0,0,0,31,41.64v-.2H26.6V40.05Z"
          />
        </g>
      </g>
    </svg>
  );
}
