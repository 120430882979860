import {PageProps} from 'gatsby';
import React from 'react';
import {ReactNode} from 'react';
import LuFooter from '../components/lu-footer';
import LuMenu from '../components/lu-menu';
import * as styles from './default.module.scss';
import { I18nextContext } from 'gatsby-plugin-react-i18next';

type PropTypes = {
  location: PageProps['location'];
  children: ReactNode;
};

export default function Layout({children, location}: PropTypes) {
  const context = React.useContext(I18nextContext);
  const isHomePage = context.originalPath === '/';
  return (
    <>
      <LuMenu theme={isHomePage ? 'white' : 'default'} float={isHomePage} />
      <main className={styles.main}>{children}</main>
      <LuFooter />
    </>
  );
}
