/**
 * 用于 Row 组件的 gutter 参数
 * 此为基值，更大的 gutter 需为基值的整数倍: https://ant.design/components/grid-cn/#components-grid-demo-gutter
 */
export const GAP_SIZE_BASE = 16;
export const GAP_SIZE_2 = GAP_SIZE_BASE * 2;
export const GAP_SIZE_3 = GAP_SIZE_BASE * 3;

export const PROD_SERVER_URL = 'https://admin.lultd.com';
export const PROD_CLIENT_URL = 'https://www.lutld.com';
