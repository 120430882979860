import React from 'react';

const svgStyle = {
  height: '1em',
};

export default function tripleAngle() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.092"
      height="15.837"
      viewBox="0 0 10.092 15.837"
      style={svgStyle}
    >
      <path
        id="路径_165"
        data-name="路径 165"
        d="M1509.392,5725.215l-4.555-4.555-4.556,4.555"
        transform="translate(-1499.791 -5709.868)"
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="1.387"
      />
      <path
        id="路径_166"
        data-name="路径 166"
        d="M1509.392,5720.331l-4.555-4.555-4.556,4.555"
        transform="translate(-1499.791 -5709.868)"
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="1.387"
      />
      <path
        id="路径_167"
        data-name="路径 167"
        d="M1509.392,5715.4l-4.555-4.555-4.556,4.555"
        transform="translate(-1499.791 -5709.868)"
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="1.387"
      />
    </svg>
  );
}
